import React, { useState, useEffect } from "react";
import { StyledGatsbyLink } from "../../styles";
import Logo from "../../images/logo.svg";
import { Base, BaseLink, Text } from "../../styles";
import styled from "styled-components";
import useViewportHeight from "../../hooks/useViewportHeight";
const NavLink = styled(BaseLink)`
  text-transform: uppercase;
  transition: all 0.2s linear;

  &:hover {
    color: ${(props) => props.theme.colors.red};
  }
`;
const Diagonals = () => /* @__PURE__ */ React.createElement(Base, {
  overflow: "hidden",
  position: "absolute",
  width: "100%",
  height: "10px",
  top: "calc(100% + 10px)",
  as: "div",
  display: "flex"
}, [...Array(100).keys()].map(() => /* @__PURE__ */ React.createElement(Base, {
  as: "div",
  bg: "white",
  height: "200%",
  width: "2px",
  style: { transform: "rotate(40deg)" },
  flex: "0 0 auto",
  mr: "4px",
  position: "relative",
  top: "-8px"
})));
const NavItem = ({ url, text, selected = false }) => /* @__PURE__ */ React.createElement(Base, {
  as: "li",
  ml: "24px",
  position: "relative"
}, /* @__PURE__ */ React.createElement(NavLink, {
  fontFamily: ["sansHeavy"],
  fontSize: "18px",
  letterSpacing: "-0.015em",
  color: selected ? "red" : "white",
  to: url
}, text), selected && /* @__PURE__ */ React.createElement(Diagonals, null));
const MobileNavItem = ({ url, text, selected = false }) => /* @__PURE__ */ React.createElement(Base, {
  as: "li",
  margin: `24px 0`,
  position: "relative",
  display: ["inline-block"],
  textAlign: "center",
  pb: selected ? "21px" : 0
}, /* @__PURE__ */ React.createElement(Base, {
  position: "relative",
  display: "inline-block",
  margin: "0 auto",
  textAlign: "center"
}, /* @__PURE__ */ React.createElement(NavLink, {
  to: url
}, /* @__PURE__ */ React.createElement(Text, {
  color: selected ? "red" : "white",
  variant: ["large-bold"]
}, text)), selected && /* @__PURE__ */ React.createElement(Diagonals, null)));
const defaultNavItems = [
  {
    text: "Engines",
    url: "/engines/"
  },
  {
    text: "Genuine Parts",
    url: "/genuine-parts/"
  },
  {
    text: "Testing",
    url: "/testing/"
  }
];
function testSelected(path, url) {
  const testStr = url.endsWith("/") ? url.slice(0, -1) : url;
  const regex = new RegExp(testStr);
  return regex.test(path);
}
const CloseButton = () => /* @__PURE__ */ React.createElement("svg", {
  width: "22",
  height: "18",
  viewBox: "0 0 22 18",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, /* @__PURE__ */ React.createElement("line", {
  x1: "3.92976",
  y1: "1.51472",
  x2: "19.4861",
  y2: "17.0711",
  stroke: "white",
  strokeWidth: "2"
}), /* @__PURE__ */ React.createElement("line", {
  x1: "2.51555",
  y1: "17.0711",
  x2: "18.0719",
  y2: "1.51473",
  stroke: "white",
  strokeWidth: "2"
}));
const HamburgerButton = () => /* @__PURE__ */ React.createElement("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  width: "22",
  height: "14",
  viewBox: "0 0 22 14",
  fill: "none"
}, /* @__PURE__ */ React.createElement("line", {
  y1: "1",
  x2: "22",
  y2: "1",
  stroke: "white",
  strokeWidth: "2"
}), /* @__PURE__ */ React.createElement("line", {
  y1: "7",
  x2: "22",
  y2: "7",
  stroke: "white",
  strokeWidth: "2"
}), /* @__PURE__ */ React.createElement("line", {
  y1: "13",
  x2: "22",
  y2: "13",
  stroke: "white",
  strokeWidth: "2"
}));
export default (props) => {
  const {
    items = defaultNavItems,
    path,
    fixed = false,
    animateMainNav = false
  } = props;
  const [openNav, setOpenNav] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const viewportHeight = useViewportHeight();
  useEffect(() => {
    setTimeout(() => setIsMounted(true), 300);
  }, []);
  return /* @__PURE__ */ React.createElement(Base, {
    as: "header",
    height: ["58px", null, "104px"],
    bg: "black",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    px: "32px",
    position: animateMainNav ? "fixed" : fixed ? "fixed" : "relative",
    zIndex: 10,
    width: "calc(100% - 64px)",
    transition: "all .5s ease-out 0.9s",
    top: animateMainNav && isMounted ? 0 : animateMainNav ? "-104px" : 0
  }, /* @__PURE__ */ React.createElement(StyledGatsbyLink, {
    to: "/",
    display: "inline-block",
    height: ["28px", null, "39px"]
  }, /* @__PURE__ */ React.createElement(Base, {
    as: Logo,
    height: "100%"
  })), /* @__PURE__ */ React.createElement(Base, {
    as: "ul",
    display: ["flex", "flex", "none"],
    alignItems: "center"
  }, /* @__PURE__ */ React.createElement(Base, {
    as: "div",
    display: "inline-block",
    cursor: "pointer",
    onClick: () => setOpenNav(!openNav)
  }, openNav ? /* @__PURE__ */ React.createElement(CloseButton, null) : /* @__PURE__ */ React.createElement(HamburgerButton, null))), /* @__PURE__ */ React.createElement(Base, {
    "data-name": "Mobile Nav",
    as: "ul",
    display: openNav ? ["flex", "flex", "flex", "none"] : "none",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    width: "100%",
    height: `${viewportHeight - 58}px`,
    top: "58px",
    left: "0px",
    backgroundColor: "black"
  }, items.map(({ url, text, selected }) => {
    const isSelected = selected || testSelected(path, url);
    return /* @__PURE__ */ React.createElement(MobileNavItem, {
      url,
      text,
      selected: isSelected,
      key: text
    });
  })), /* @__PURE__ */ React.createElement(Base, {
    as: "ul",
    display: ["none", null, "flex"],
    alignItems: "center"
  }, items.map(({ url, text, selected }) => {
    const isSelected = selected || testSelected(path, url);
    return /* @__PURE__ */ React.createElement(NavItem, {
      key: url,
      url,
      text,
      selected: isSelected
    });
  })));
};
