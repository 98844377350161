import React from "react";
import { BaseLink, Text } from "../styles";
import styled from "styled-components";
import theme from "../styles/theme";
import { variant } from "styled-system";
const CTALink = styled(BaseLink)(variant({
  variants: {
    red: {
      background: theme.colors.red,
      border: `3px solid ${theme.colors.red}`,
      color: "white"
    },
    "white-outline": {
      background: "none",
      border: `3px solid`,
      borderColor: theme.colors.white,
      color: "white",
      transition: "all .2s linear",
      "&:hover": {
        borderColor: theme.colors.red,
        color: theme.colors.red
      }
    }
  }
}));
const CTAButton = ({ link, backgroundStyle, target }) => {
  return /* @__PURE__ */ React.createElement(CTALink, {
    to: link.url,
    target,
    variant: backgroundStyle,
    p: ["10px 20px"],
    textTransform: "uppercase"
  }, /* @__PURE__ */ React.createElement(Text, {
    variant: ["small-bold"]
  }, link.text));
};
CTAButton.defaultProps = {
  isFontResponsive: true,
  backgroundStyle: "red"
};
export default CTAButton;
