import { useState, useEffect } from "react";
export default () => {
  if (typeof window === "undefined")
    return 700;
  const [height, setHeight] = useState(window.innerHeight);
  const listener = () => {
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);
  return height;
};
