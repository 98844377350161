import styled from "styled-components";
import GatsbyLink from "gatsby-link";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  color,
  space,
  typography,
  variant,
  layout,
  border,
  flexbox,
  compose,
  position,
  grid,
  system
} from "styled-system";
const allSystems = compose(color, space, typography, variant, layout, border, flexbox, position, grid, system({
  placeItems: true,
  textDecoration: true,
  textIndent: true,
  textTransform: true,
  transform: true,
  transition: true,
  whiteSpace: true,
  objectFit: true,
  objectPosition: true,
  cursor: true,
  outline: true,
  touchAction: true,
  userSelect: true,
  pointerEvents: true,
  userDrag: true
}));
export const Base = styled("span")(allSystems);
const textVariants = variant({
  variants: {
    ultra: {
      fontFamily: "sansHeavy",
      fontWeight: "700",
      lineHeight: "100%",
      fontSize: "ultra",
      letterSpacing: "-0.055em"
    },
    "xxx-large": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "xxx-large",
      lineHeight: "150px",
      letterSpacing: "-0.055em"
    },
    "xx-large": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "xx-large",
      lineHeight: "100%",
      letterSpacing: "-0.025em"
    },
    "x-large": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "x-large",
      lineHeight: "100%",
      letterSpacing: "-0.025em"
    },
    "x-large-bold": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "x-large",
      lineHeight: "64px",
      letterSpacing: "-0.025em"
    },
    large: {
      fontFamily: "sans",
      fontWeight: 400,
      fontSize: "large",
      lineHeight: "1.1em",
      letterSpacing: "-0.035em"
    },
    "large-bold": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "large",
      lineHeight: "100%",
      letterSpacing: "-0.035em"
    },
    medium: {
      fontFamily: "sans",
      fontWeight: 400,
      fontSize: "medium",
      lineHeight: "35px",
      letterSpacing: "-0.035em"
    },
    "medium-bold": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "medium",
      lineHeight: "35px",
      letterSpacing: "-0.035em"
    },
    small: {
      fontFamily: "sans",
      fontWeight: 700,
      fontSize: "small",
      lineHeight: "26.21px",
      letterSpacing: "-0.015em"
    },
    "small-bold": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "small",
      lineHeight: "20px",
      letterSpacing: "-0.015em"
    },
    "x-small": {
      fontFamily: "sans",
      fontWeight: 400,
      fontSize: "x-small",
      lineHeight: "23px",
      letterSpacing: "-0.015em"
    },
    "xx-small": {
      fontFamily: "sans",
      fontWeight: 400,
      fontSize: "xx-small",
      lineHeight: "20px",
      letterSpacing: "-0.015em"
    },
    "xxx-small": {
      fontFamily: "sans",
      fontWeight: "normal",
      fontSize: "xxx-small",
      lineHeight: "20px",
      letterSpacing: "-0.015em"
    },
    "xxx-small-bold": {
      fontFamily: "sansHeavy",
      fontWeight: 700,
      fontSize: "xxx-small",
      lineHeight: "20px",
      letterSpacing: "-0.015em"
    }
  }
});
export const Text = styled(Base)(textVariants);
export const StyledGatsbyLink = styled(GatsbyLink)(allSystems);
export const BaseLink = styled(StyledGatsbyLink)`
  text-decoration: none;
`;
export const StyledGatsbyImage = styled(GatsbyImage)(allSystems);
